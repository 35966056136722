/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/iframe-has-title */
import './App.less';
import $ from 'jquery';
import React, { useState, useEffect, useRef } from 'react';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { DownOutlined } from '@ant-design/icons';
import { Dropdown, Space, Breadcrumb, Menu } from 'antd';
import {
  AppstoreOutlined,
  EditOutlined,
  FundProjectionScreenOutlined
} from '@ant-design/icons';
export default function Collection() {
  let sliderRef = useRef(null);
  const [list, setList] = useState([])
  const [heiList, setHeiList] = useState([])
  const [iframeUrl,setIframeUrl] = useState(list.length > 0 ? list[0].src : '')
  const [height, setHeight] = useState();
  const [listNum, setListNum] = useState(0);
  const [showCover, setShowCover] = useState(false);
  const [items, setItems] = useState([]);
  const [menuItems, setMenuItems] = useState([
    {
      key: 'sub1',
      label: '通用模型',
      icon: <AppstoreOutlined />,
      children: [
        {
          "key": "0",
          "label": "DeepSeek-R1",
          "name": "DeepSeek-R1",
          "img": "https://geyuan-back.oss-cn-beijing.aliyuncs.com/AI%E5%AE%9D%E5%BA%93/images/deepseek.png",
          "hint": "幻方量化旗下深度求索推出的开源大模型和聊天助手",
          "src": "https://fastgpt.geyuandiaoyan.com/chat/share?shareId=paclccf8el9o1ue5ly75tv27"
        },
        {
          "key": "1",
          "label": "DeepSeek-V3",
          "name": "DeepSeek-V3",
          "img": "https://geyuan-back.oss-cn-beijing.aliyuncs.com/AI%E5%AE%9D%E5%BA%93/images/deepseek.png",
          "hint": "幻方量化旗下深度求索推出的开源大模型和聊天助手",
          "src": "https://fastgpt.geyuandiaoyan.com/chat/share?shareId=5syrca0s2ajb3wt1xfzhunf7"
        },
        {
          "key": "2",
          "label": "ChatGPT",
          "name": "ChatGPT",
          "img": "https://ai-bot.cn/wp-content/uploads/2023/03/openai-gpt-model.png",
          "hint": "ChatGPT",
          "src": "https://fastgpt.geyuandiaoyan.com/chat/share?shareId=vpm816ww4dzioy68j1nibu7v"
        },
        {
          "key": "3",
          "label": "豆包",
          "name": "豆包",
          "img": "https://geyuan-back.oss-cn-beijing.aliyuncs.com/AI%E5%AE%9D%E5%BA%93/images/doubao.png",
          "hint": "字节跳动推出的免费AI对话助手",
          "src": "https://fastgpt.geyuandiaoyan.com/chat/share?shareId=qorgzkuufog4n319h8s5d28e"
        },
        {
          "key": "4",
          "label": "文心一言",
          "name": "文心一言",
          "img": "https://geyuan-back.oss-cn-beijing.aliyuncs.com/AI%E5%AE%9D%E5%BA%93/images/wenxin.png",
          "hint": "百度推出的基于文心大模型的AI对话互动工具",
          "src": "https://fastgpt.geyuandiaoyan.com/chat/share?shareId=bzd5fphfsjzcawwyaj8jbkm2"
        },
        {
          "key": "5",
          "label": "腾讯混元",
          "name": "腾讯混元",
          "img": "https://geyuan-back.oss-cn-beijing.aliyuncs.com/AI%E5%AE%9D%E5%BA%93/images/hunyuan.png",
          "hint": "腾讯推出的免费AI智能助手",
          "src": "https://fastgpt.geyuandiaoyan.com/chat/share?shareId=qfzo19pd2swmlwc7g9hlg5hu"
        },
        {
          "key": "6",
          "label": "通义千问",
          "name": "通义千问",
          "img": "https://geyuan-back.oss-cn-beijing.aliyuncs.com/AI%E5%AE%9D%E5%BA%93/images/tongyi.png",
          "hint": "阿里巴巴推出的类ChatGPT响应人类指令的大模型",
          "src": "https://fastgpt.geyuandiaoyan.com/chat/share?shareId=axq1po71r759nniowynz366s"
        },
        {
          "key": "7",
          "label": "Kimi",
          "name": "Kimi",
          "img": "https://geyuan-back.oss-cn-beijing.aliyuncs.com/AI%E5%AE%9D%E5%BA%93/images/kimi.png",
          "hint": "超大“内存”助手，可以一口气读完二十万字的小说",
          "src": "https://fastgpt.geyuandiaoyan.com/chat/share?shareId=0k5kl2zgfc2qoae33rnzttgh"
        },
        {
          "key": "8",
          "label": "讯飞星火",
          "name": "讯飞星火",
          "img": "https://geyuan-back.oss-cn-beijing.aliyuncs.com/AI%E5%AE%9D%E5%BA%93/images/xunfei.png",
          "hint": "科大讯飞推出的类ChatGPT的讯飞星火认知大模型",
          "src": "https://fastgpt.geyuandiaoyan.com/chat/share?shareId=fbkd6jrv5r8elllaatbf08rz"
        },
        {
          "key": "9",
          "label": "360智脑",
          "name": "360智脑",
          "img": "https://geyuan-back.oss-cn-beijing.aliyuncs.com/AI%E5%AE%9D%E5%BA%93/images/360.png",
          "hint": "360搜索最新推出的AI对话聊天机器人",
          "src": "https://fastgpt.geyuandiaoyan.com/chat/share?shareId=uti3u0l1w5345rnldmde3qu1"
        },
        {
          "key": "10",
          "label": "百小应",
          "name": "百小应",
          "img": "https://geyuan-back.oss-cn-beijing.aliyuncs.com/AI%E5%AE%9D%E5%BA%93/images/baixiao.jpg",
          "hint": "百川智能推出的免费AI助手",
          "src": "https://fastgpt.geyuandiaoyan.com/chat/share?shareId=gyuet1ji1x62mal30rteakul"
        },
        {
          "key": "11",
          "label": "天工",
          "name": "天工",
          "img": "https://geyuan-back.oss-cn-beijing.aliyuncs.com/AI%E5%AE%9D%E5%BA%93/images/tiangong.png",
          "hint": "昆仑万维最新推出的结合大模型的AI搜索引擎",
          "src": "https://fastgpt.geyuandiaoyan.com/chat/share?shareId=fl2ckj71yzvmbgod4ym02402"
        },
        {
          "key": "12",
          "label": "智谱清言",
          "name": "智谱清言",
          "img": "https://geyuan-back.oss-cn-beijing.aliyuncs.com/AI%E5%AE%9D%E5%BA%93/images/zhipu.png",
          "hint": "智谱AI推出的生成式AI助手，基于ChatGLM 2",
          "src": "https://fastgpt.geyuandiaoyan.com/chat/share?shareId=mjxdbb8irnlo1240d1465f9t"
        },
      ],
    },
    {
      key: 'sub2',
      label: '写作模型',
      icon: <EditOutlined />,
      children: [
        {
          "key": "13",
          "label": "通知写手",
          "name": "通知写手",
          "img": "",
          "hint": "通知写手",
          "src": "https://fastgpt.geyuandiaoyan.com/chat/share?shareId=h2nglof4xjwr602si5fywup9"
        },
        {
          "key": "14",
          "label": "通报写手",
          "name": "通报写手",
          "img": "",
          "hint": "通报写手",
          "src": "https://fastgpt.geyuandiaoyan.com/chat/share?shareId=ydsdr4vnee00sgpm936v5js2"
        },
        {
          "key": "15",
          "label": "报告写手",
          "name": "报告写手",
          "img": "",
          "hint": "报告写手",
          "src": "https://fastgpt.geyuandiaoyan.com/chat/share?shareId=inh15vg7uan0hsxukgte3wea"
        },
        {
          "key": "16",
          "label": "请示写手",
          "name": "请示写手",
          "img": "",
          "hint": "请示写手",
          "src": "https://fastgpt.geyuandiaoyan.com/chat/share?shareId=gre11dclck3pat2av9nuyv3r"
        },
        {
          "key": "17",
          "label": "批复写手",
          "name": "批复写手",
          "img": "",
          "hint": "批复写手",
          "src": "https://fastgpt.geyuandiaoyan.com/chat/share?shareId=l1hpdyw149murx7misu8p3vt"
        },
        {
          "key": "18",
          "label": "函写手",
          "name": "函写手",
          "img": "",
          "hint": "函写手",
          "src": "https://fastgpt.geyuandiaoyan.com/chat/share?shareId=y6d9olqak5atpvk9vu9xlwvi"
        },
        {
          "key": "19",
          "label": "纪要写手",
          "name": "纪要写手",
          "img": "",
          "hint": "纪要写手",
          "src": "https://fastgpt.geyuandiaoyan.com/chat/share?shareId=lasabbajbtyvzuuqq6ldg2iv"
        },
        {
          "key": "20",
          "label": "新闻写手",
          "name": "新闻写手",
          "img": "",
          "hint": "新闻写手",
          "src": "https://fastgpt.geyuandiaoyan.com/chat/share?shareId=ys5ovricet78mnjpt5p3l0he"
        },
        {
          "key": "21",
          "label": "党建材料写手",
          "name": "党建材料写手",
          "img": "",
          "hint": "党建材料写手",
          "src": "https://fastgpt.geyuandiaoyan.com/chat/share?shareId=bvjjw5wh7diuszurxn8ldsm6"
        },
      ],
    }
  ])
  const [materialData, setMaterialData] = useState([
    {
      key: 'sub1',
      label: '材料模型',
      icon: <FundProjectionScreenOutlined />,
      children: [
        {
          "key": "0",
          "label": "秘塔",
          "name": "秘塔",
          "img": "https://ai-bot.cn/wp-content/uploads/2024/06/metaso-ai-search-icon.png",
          "hint": "秘塔AI",
          "src": "https://metaso.cn/?s=sbypcpz&referrer_s=sbypcpz"
        },
        {
          "key": "2",
          "label": "省委书记讲话学习助手",
          "name": "省委书记讲话学习助手",
          "img": "",
          "hint": "省委书记讲话学习助手",
          "src": "https://fastgpt.geyuandiaoyan.com/chat/share?shareId=ayt6xkpamts9jwuussi7x7ov"
        },
        {
          "key": "3",
          "label": "省长讲话学习助手",
          "name": "省长讲话学习助手",
          "img": "",
          "hint": "省长讲话学习助手",
          "src": "https://fastgpt.geyuandiaoyan.com/chat/share?shareId=j79lcjf3xf76emizn1cqqvd6"
        },
        {
          "key": "4",
          "label": "人才政策助手",
          "name": "人才政策助手",
          "img": "",
          "hint": "人才政策助手",
          "src": "https://fastgpt.geyuandiaoyan.com/chat/share?shareId=4x4xx394wfbplpan1088q3wy"
        }
      ]
    },
  ])
  const [specialData, setSpecialData] = useState([
    {
      key: 'sub1',
      label: '专用模型',
      icon: <FundProjectionScreenOutlined />,
      children: [
        {
          "key": "0",
          "label": "法律顾问",
          "name": "法律顾问",
          "img": "https://ai-bot.cn/wp-content/uploads/2024/04/tongyi-icon.png",
          "hint": "法律顾问",
          "src": "https://fastgpt.geyuandiaoyan.com/chat/share?shareId=sk1rbbudejls0255oubski2o"
        },
      ],
    }
  ])
  const [graphicDesign, setGraphicDesign] = useState([
    {
      key: 'sub1',
      label: '图片设计',
      icon: <FundProjectionScreenOutlined />,
      children: [
        {
          "key": "0",
          "label": "美图设计",
          "name": "美图设计",
          "img": "https://ai-bot.cn/wp-content/uploads/2024/04/tongyi-icon.png",
          "hint": "Ai图像创作",
          "src": "https://www.designkit.com/poster/"
        },
      ],
    }
  ])
  const [livepptData, setLivepptData] = useState([
    {
      key: 'sub1',
      label: '制作PPT',
      icon: <FundProjectionScreenOutlined />,
      children: [
        {
          "key": "0",
          "label": "LivePPT",
          "name": "LivePPT",
          "img": "https://ai-bot.cn/wp-content/uploads/2024/04/tongyi-icon.png",
          "hint": "一句话生成高质量PPT",
          "src": "https://www.designkit.com/ppt/"
      }
      ]
    }
  ])
  const [defaultKey, setDefaultKey] = useState('0')
  const [defaultOpenKeys, setDefaultOpenKeys] = useState('sub1')

  useEffect(() => {
    getListData()
    // 设置屏幕监听
    window.addEventListener("resize", handleWindowResize)
    return () => {
        window.removeEventListener("resize", handleWindowResize)
    }
  }, [])

  const getListData = () => {
    let temp = menuItems;
    setList(temp)
    let list = menuItems[0].children;
    setIframeUrl(list.length > 0 ? list[0].src : '')
    setSliderOpe();
  }

  const handleWindowResize = () => {
    setSliderOpe()
  }

  const setSliderOpe = () => {
    let list = [{
      img: require('./image/1.png'),
      str: '写材料',
      inc: '文思智涌，妙笔自生',
      funBool: true
    },{
      img: require('./image/2.png'),
      str: '找材料',
      inc: '输入需求，秒出材料',
      funBool: true
    },{
      img: require('./image/6.png'),
      str: '问专家',
      inc: 'AI专家，秒回答',
      funBool: true
    },{
      img: require('./image/3.png'),
      str: '做图片',
      inc: '输入想法，秒出海报',
      funBool: true
    },{
      img: require('./image/4.png'),
      str: '做PPT',
      inc: '输入文案，秒变PPT',
      funBool: true
    },{
      img: require('./image/5.png'),
      str: '做视频',
      inc: 'AI剪片，秒出大片',
      funBool: false
    }];
    let itemList = [{
      label: (
        <div onClick={() => {changeSlider(0)}}>写材料</div>
      ),
      key: '0',
    },
    {
      label: (
        <div onClick={() => {changeSlider(1)}}>找材料</div>
      ),
      key: '1',
    },
    {
      label: (
        <div onClick={() => {changeSlider(2)}}>问专家</div>
      ),
      key: '2',
    },
    {
      label: (
        <div onClick={() => {changeSlider(3)}}>做图片</div>
      ),
      key: '3',
    },
    {
      label: (
        <div onClick={() => {changeSlider(4)}}>做PPT</div>
      ),
      key: '4',
    },
    {
      label: '做视频',
      key: '5',
      disabled: true,
    }];
    let width = document.documentElement.clientWidth;
    if(width > 1591){
      setItems([]);
      let temp = list.slice(0, 6);
      setHeiList(temp);
    }else if(width > 1431 && width < 1590) {
      setItems(itemList.slice(itemList.length - 1, itemList.length));
      let temp = list.slice(0, 5);
      setHeiList(temp);
    }else if(width < 1430 && width > 1271) {
      setItems(itemList.slice(itemList.length - 2, itemList.length));
      let temp = list.slice(0, 4);
      setHeiList(temp);
    } else if(width < 1270 && width > 1051) {
      setItems(itemList.slice(itemList.length - 3, itemList.length));
      let temp = list.slice(0, 3);
      setHeiList(temp);
    } else if(width < 1050 && width > 850) {
      setItems(itemList.slice(itemList.length - 4, itemList.length));
      let temp = list.slice(0, 2);
      setHeiList(temp);
    } else if(width < 850) {
      setItems(itemList.slice(itemList.length - 5, itemList.length));
      let temp = list.slice(0, 1);
      setHeiList(temp);
    }
    let sliderHei = (document.documentElement.scrollHeight) - 120;
    setHeight(sliderHei)
  }

  const changeSlider = (index) => {
    let list = [];
    if(index == 0) {
      list = menuItems;
      setList(list);
      setSliderOpe();
      setIframeUrl(list.length > 0 ? list[0].children[0].src : '');
      setListNum(index);
    } else if(index == 1) {
      list = materialData;
      setList(list);
      setSliderOpe();
      setIframeUrl(list.length > 0 ? list[0].children[0].src : '');
      setListNum(index);
    } else if(index == 2) {
      list = specialData;
      setList(list);
      setSliderOpe();
      setIframeUrl(list.length > 0 ? list[0].children[0].src : '');
      setListNum(index);
    } else if(index == 3) {
      list = graphicDesign;
      setList(list);
      setSliderOpe();
      setIframeUrl(list.length > 0 ? list[0].children[0].src : '');
      setListNum(index);
    } else if(index == 4) {
      list = livepptData;
      setList(list);
      setSliderOpe();
      setIframeUrl(list.length > 0 ? list[0].children[0].src : '');
      setListNum(index);
    }
    setDefaultKey('0');
    setDefaultOpenKeys('sub1');
  }

  const onClick = (e) => {
    let keyPath = e.keyPath;
    if(e.keyPath.length > 1) {
      let temp = [];
      if(listNum == 0) {
        temp = menuItems;
      } else if(listNum == 1) {
        temp = materialData;
      } else if(listNum == 2) {
        temp = specialData;
      } else if(listNum == 3) {
        temp = graphicDesign;
      } else if(listNum == 4) {
        temp = livepptData;
      }
      setDefaultKey(keyPath[0]);
      setDefaultOpenKeys(keyPath[1]);
      const subObject = temp.find(item => item.key === keyPath[1]);
      const subChi = (subObject.children).find(item => item.key === keyPath[0]);
      setIframeUrl(subChi.src)
      // localStorage.setItem('localIframe', subChi.src);
      // localStorage.setItem('localDefaultKey', subObject.key);
      // localStorage.setItem('localDefaultOpenKeys', subChi.key);
    } else {
    }
  };

  return (
    <div className="collection_con">
      <div className='collection_cover' style={showCover ? {display: 'flex'} : {display: 'none'}} onClick={() => {setShowCover(false)}}>
        <div>
          <img src={require('./image/cooperate_bg.png')} />
        </div>
      </div>
      <div className='collection_head'>
        <div className='collection_logo' onClick={() => {window.history.go(0)}}>
          <img src={require('./image/logo.png')} />
          AI百宝箱
        </div>
        <div className='collection_list'>
        {
          heiList.length > 0 ? heiList.map((item, index) => {
            return (
              <div className='slider_box' key={index} onClick={() => {changeSlider(index)}}>
                <div style={{width: 200}}>
                  <div className={item.funBool && listNum == index?'slider_chi':'slider_chi spe_box'}>
                    <img src={item.img}/>
                    <div>
                      <p>{item.str}</p>
                      <p>{item.inc}</p>
                    </div>
                    <div style={{display: item.funBool?'none':'block'}} className='fun_box'>敬请期待</div>
                  </div>
                </div>
              </div>
            )}) : ''
        }
        <div style={items.length == 0 ? {display: 'none'} : {display: 'block'}} className='dropdown_box'>
          <Dropdown menu={{ items }}>
            <Space>
              功能选择
              <DownOutlined />
            </Space>
          </Dropdown>
        </div>
        </div>
        <div className='cooperate' onClick={() => {setShowCover(true)}}>商务合作</div>
      </div>
      <div className='collection_body'>
        <div className='collection_cho'
          style={{
            height: height + "px",
          }}
        >
        <Menu
          onClick={onClick}
          style={{ width: 180 }}
          defaultSelectedKeys={['0']}
          defaultOpenKeys={['sub1']}
          // openKeys={[defaultOpenKeys]}
          selectedKeys={[defaultKey]}
          mode="inline"
          items={list}
        />
        </div>
        <iframe
          name="iframe"
          scrolling="auto"
          align="left" 
          src={iframeUrl}
        />
      </div>
    </div>
  )
}